import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

const AppEnglish = React.lazy(() => import('./AppEnglish'));
const AppChinese = React.lazy(() => import('./AppChinese'));
const AppPolicy = React.lazy(() => import('./AppPolicy'));
const AccountDeletion = React.lazy(() => import('./AccountDeletion'));

function App() {
  return (
    <Router>
      <div>
        <nav className="navbar">
          <ul className="nav-links">
            <li>
              <Link to="/">English</Link>
            </li>
            <li>
              <Link to="/chinese">中文</Link>
            </li>
            <li>
              <Link to="/privacy">Data and Privacy Policy</Link>
            </li>
            <li>
              <Link to="/account-deletion">Account Deletion</Link>
            </li>
          </ul>
        </nav>
        <div className="content-container">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/chinese" element={<AppChinese />} />
              <Route path="/" element={<AppEnglish />} />
              <Route path="/privacy" element={<AppPolicy />} />
              <Route path="/account-deletion" element={<AccountDeletion />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </Router>
  );
}

export default App;
